"use client";
import {
	Button,
	Divider,
	NavbarItem,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Skeleton,
	User,
} from "@nextui-org/react";
import { useEffect } from "react";
import { useAuth } from "../Auth";
import { UserCreditsDisplay } from "./UserCreditsDisplay";
import { AdminPanel } from "./AdminPanel";
import { getMixPanelClient } from "@/app/mixpanel";
import { LogoutButton } from "../Auth/LogoutButton";
import { BetaFeedback } from "./BetaFeedback";
// import { getSessionId } from "@/app/mixpanel/getSessions";
import * as Sentry from "@sentry/nextjs";

export const SignInButton = () => {
	const { user, openAuthModal, isLoading, isAuthenticated } = useAuth();
	const mp = getMixPanelClient();

	useEffect(() => {
		if (isAuthenticated && user) {
			mp.identify(user?.sub!);
			mp.set({
				$email: user?.email,
				$nickname: user?.nickname,
				$name: user?.name,
			});
			Sentry.setUser({
				email: user.email!,
				id: user.sub!,
				username: user.nickname!,
			});
		}
	}, [isAuthenticated, mp, user]);

	// useEffect(() => {
	// 	if (
	// 		!isAuthenticated &&
	// 		!isLoading &&
	// 		sessionStorage.getItem("authModalSeen") === null
	// 	) {
	// 		openAuthModal();
	// 	}
	// }, [isAuthenticated, isLoading, openAuthModal, user]);

	if (isLoading)
		return (
			<NavbarItem>
				<div className="w-[100px] sm:w-[200px] flex items-center gap-3">
					<div className="ml-auto">
						<Skeleton
							className="flex rounded-full w-8 h-8"
							style={{
								backgroundColor: "hsl(var(--nextui-content1)",
							}}
						/>
					</div>
					<div className="w-full flex flex-col gap-2">
						<Skeleton
							className="h-2 sm:h-3 w-2/5 rounded-lg"
							style={{
								backgroundColor: "hsl(var(--nextui-content1)",
							}}
						/>
						<Skeleton
							className="h-2 sm:h-3 w-3/5 rounded-lg"
							style={{
								backgroundColor: "hsl(var(--nextui-content1)",
							}}
						/>
					</div>
				</div>
			</NavbarItem>
		);
	return (
		<NavbarItem>
			{isAuthenticated && user ? (
				<Popover placement="bottom">
					<PopoverTrigger>
						<User
							as={"button"}
							className="transition-transform"
							avatarProps={{
								src: user.picture ?? undefined,
								size: "sm",
								classNames: { base: "h-7 w-7 sm:h-8 sm:w-8" },
							}}
							name={
								<span className="text-xs sm:text-sm  text-primary font-medium">
									{user.nickname}
								</span>
							}
							description={<UserCreditsDisplay />}
						/>
					</PopoverTrigger>
					<PopoverContent className="p-4">
						<span>{user.email}</span>
						<div className="mt-4">
							<BetaFeedback />
						</div>
						<Divider className="my-4" />
						{user["skibs/roles"]?.includes("admin") && <AdminPanel />}
						{/* <CreateBetaUserButton /> */}
						<LogoutButton />
					</PopoverContent>
				</Popover>
			) : (
				<Button
					color="primary"
					variant="ghost"
					className="rounded-full hover:text-white"
					onClick={() => openAuthModal()}
				>
					Sign in
				</Button>
			)}
		</NavbarItem>
	);
};
