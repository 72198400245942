"use client";

import { Button, useDisclosure } from "@nextui-org/react";
import { IoMegaphone } from "react-icons/io5";
import { ReportBugModal } from "./ReportBugModal";

export const ReportBugButton: React.FC = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Button
				startContent={<IoMegaphone className="h-3 w-3 sm:h-5 sm:w-5" />}
				className="fixed bottom-4 right-6 sm:bottom-10 sm:right-10 text-xs sm:text-sm h-8  min-h-8 sm:h-10 sm:min-h-10  md:h-10 md:min-h-10"
				onPress={onOpen}
			>
				Report a bug
			</Button>
			<ReportBugModal isOpen={isOpen} onClose={onClose} />
		</>
	);
};
