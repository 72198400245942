import { Button, NavbarItem } from "@nextui-org/react";
import { useState } from "react";
import { BetaFeedbackModal } from "./BetaFeedbackModal";
import { IoChatbubbleEllipses } from "react-icons/io5";

export const BetaFeedback: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);

	const closeModal = () => {
		setIsOpen(false);
	};

	return (
		<>
			<NavbarItem>
				<Button
					variant="light"
					onPress={() => {
						setIsOpen(true);
					}}
					startContent={
						<IoChatbubbleEllipses size={18} className="hidden sm:block" />
					}
					endContent={
						<IoChatbubbleEllipses size={24} className="block sm:hidden" />
					}
					className="px-0 sm:px-4 font-medium"
				>
					Leave a feedback
				</Button>
			</NavbarItem>
			<BetaFeedbackModal isOpen={isOpen} onOpenChange={closeModal} />
		</>
	);
};
