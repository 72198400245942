import Image from "next/image";

export const SkibsLogo: React.FC = () => {
	return (
		<Image
			src="/skibs.svg"
			alt="Skibs Logo"
			width={200}
			height={50}
			className="w-[5.5rem]"
		/>
	);
};
