import {
	Modal,
	Button,
	ModalContent,
	ModalHeader,
	ModalBody,
	Input,
	Textarea,
} from "@nextui-org/react";
import { error } from "console";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useAuth } from "../Auth";

interface BetaFeedbackModalProps {
	isOpen: boolean;
	onOpenChange: () => void;
}

export const BetaFeedbackModal: React.FC<BetaFeedbackModalProps> = ({
	isOpen,
	onOpenChange,
}) => {
	const { user } = useAuth();
	const [email, setEmail] = useState("");
	const [feedback, setFeedback] = useState("");
	const [errors, setErrors] = useState<{
		email?: string;
		feedback?: string;
	}>({});

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitText, setSubmitText] = useState("Submit");

	useEffect(() => {
		if (user) {
			setEmail(user.email || "");
		}
	}, [user]);

	useEffect(() => {
		if (!isOpen) {
			setEmail(user?.email || "");
			setFeedback("");
			setErrors({});
			setIsSubmitting(false);
			setSubmitText("Submit");
		}
	}, [isOpen, user?.email]);

	const validateForm = () => {
		const newErrors: typeof errors = {};
		if (!email || email.trim() === "") {
			newErrors.email = "Email is required";
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = "Email is invalid";
		}
		if (!feedback || feedback.trim() === "") {
			newErrors.feedback = "Feedback is required";
		} else if (feedback.length < 10) {
			newErrors.feedback = "Feedback is too short";
		} else if (feedback.length > 1000) {
			newErrors.feedback = "Feedback is too long";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSubmit = async () => {
		if (validateForm()) {
			setIsSubmitting(true);
			setSubmitText("Submitting...");
			const formData = new FormData();
			formData.append("email", email);
			formData.append("feedback", feedback);
			const response = await fetch("/api/v1/feedback", {
				method: "POST",
				body: formData,
			});
			if (!response.ok) {
				const error = await response.json();
				setErrors({ ...errors, email: error["error"] });
				setIsSubmitting(false);
				setSubmitText("Submit");
			} else {
				setSubmitText("Submitted!");
				setIsSubmitting(false);
				setTimeout(() => {
					setSubmitText("Submit");
					setEmail("");
					setFeedback("");
				}, 3000);
			}
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onOpenChange={onOpenChange}
			placement="top-center"
			backdrop="blur"
			closeButton={
				<Button isIconOnly variant="light">
					<IoClose size={24} />
				</Button>
			}
		>
			<ModalContent className="p-4">
				{(onClose) => (
					<>
						<ModalHeader className="flex flex-col gap-1">
							<span className="text-3xl text-center">
								Help shape our product
							</span>
						</ModalHeader>
						<ModalBody>
							<span className="text-center text-medium font-medium mb-5">
								Your feedback is crucial in refining our beta product. Share
								your thoughts, experiences, or suggestions to help us create the
								best possible version for you.
							</span>
							<div className="w-full text-center space-y-3">
								<Input
									type="email"
									variant="bordered"
									label={"email"}
									autoFocus={user ? false : true}
									isRequired
									onChange={(e) => setEmail(e.target.value)}
									errorMessage={errors.email}
									isInvalid={!!errors.email}
									value={email}
								/>
								<Textarea
									label="Feedback"
									placeholder="Enter your feedback"
									variant="bordered"
									autoFocus={user ? true : false}
									isRequired
									value={feedback}
									onChange={(e) => setFeedback(e.target.value)}
									errorMessage={errors.feedback}
									isInvalid={!!errors.feedback}
								/>
								<Button
									onPress={handleSubmit}
									isLoading={isSubmitting}
									color={submitText === "Submitted!" ? "success" : "primary"}
								>
									<span className="font-medium">{submitText}</span>
								</Button>
							</div>
						</ModalBody>
					</>
				)}
			</ModalContent>
		</Modal>
	);
};
