import { getMixPanelClient } from "@/app/mixpanel";
import { Button } from "@nextui-org/react";
import { IoLogOut } from "react-icons/io5";
import { useAuth } from "./AuthContext";
import * as Sentry from "@sentry/nextjs";

export const LogoutButton: React.FC = () => {
	const mp = getMixPanelClient();
	const { isAuthenticated } = useAuth();
	if (!isAuthenticated) return null;
	return (
		<Button
			className="px-0 font-medium"
			variant="light"
			as={"a"}
			href="/api/auth/logout"
			onPress={() => {
				mp.reset();
				Sentry.setUser(null);
			}}
			endContent={<IoLogOut size={24} />}
		>
			Logout
		</Button>
	);
};
