"use client";

import { EventName, getMixPanelClient } from "@/app/mixpanel";
import { NavbarItem } from "@nextui-org/react";
import { FaDiscord } from "react-icons/fa";

export const Discord: React.FC = () => {
	const mp = getMixPanelClient();

	return (
		<a
			href="https://discord.gg/xYY3x4tZVF"
			target="_blank"
			rel="noopener noreferrer"
			className="flex items-center gap-2"
			onClick={() => {
				mp.track(EventName.DiscordLinkClick);
			}}
		>
			<span className="sm:hidden text-sm">Join our Discord</span>
			<FaDiscord
				size={24}
				className="text-white hover:text-purple-400 transition-colors"
			/>
		</a>
	);
};
