"use client";

import React, { useMemo } from "react";
import {
	Button,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownTrigger,
} from "@nextui-org/react";
import type { Selection } from "@nextui-org/react";

const categories = [
	{
		key: "character_head_problem",
		label: "Head generation",
	},
	{
		key: "character_body_problem",
		label: "Body generation",
	},
	{ key: "animation_problem", label: "Animation" },
	{ key: "loading_stuck_problem", label: "Loading stuck" },
	{ key: "other", label: "Other" },
	{ key: "suggestion", label: "Suggestion" },
] as const;

interface ReportBugCategoryProps {
	selectedCategory: Selection;
	onCategoryChange: (category: Selection) => void;
}

export const ReportBugCategory: React.FC<ReportBugCategoryProps> = ({
	selectedCategory,
	onCategoryChange,
}) => {
	const displayedCategory = useMemo(() => {
		const selected = Array.from(selectedCategory)[0] as string;
		if (selected === "Pick a category") return selected;

		const foundCategory = categories.find((cat) => cat.key === selected);
		return foundCategory
			? foundCategory.label.charAt(0).toUpperCase() +
					foundCategory.label.slice(1)
			: "Pick a category";
	}, [selectedCategory]);

	return (
		<>
			<span className="text-left w-full text-xs text-white/80">
				Category <span className="text-danger">*</span>
			</span>
			<Dropdown className="w-full">
				<DropdownTrigger className="w-full">
					<Button variant="bordered">{displayedCategory}</Button>
				</DropdownTrigger>
				<DropdownMenu
					variant="bordered"
					disallowEmptySelection
					selectionMode="single"
					selectedKeys={selectedCategory}
					onSelectionChange={onCategoryChange}
				>
					{categories.map(({ key, label }) => (
						<DropdownItem key={key}>{label}</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		</>
	);
};
