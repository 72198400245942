
import { useAuth } from "../Auth";
import { useEffect, useMemo } from "react";
import { Skeleton } from "@nextui-org/react";
import React from "react";

export const UserCreditsDisplay: React.FC = React.memo(() => {
	const { userData, isAuthenticated } = useAuth();

	const creditsDisplay = useMemo(() => {
		if (!isAuthenticated) return null;
		if (!userData)
			return (
				<div className="w-16 sm:w-24 h-3">
					<Skeleton
						className="h-2 sm:h-3 w-3/5 rounded-lg"
						style={{
							backgroundColor: "hsl(var(--nextui-content1)",
						}}
					/>
				</div>
			);
		return (
			<span className="text-white/75 text-xs sm:text-sm">
				Credits:{" "}
				<span className="font-medium text-white/90">{userData.credits}</span>
			</span>
		);
	}, [isAuthenticated, userData]);

	return creditsDisplay;
});

UserCreditsDisplay.displayName = "UserCreditsDisplay";
