import { Chip, NavbarItem } from "@nextui-org/react";

export const BetaAccessChip: React.FC = () => {
	return (
		<NavbarItem>
			<Chip
				variant="shadow"
				size="sm"
				radius="sm"
				classNames={{
					base: "bg-gradient-to-br from-indigo-500 to-pink-500 border-small border-white/50 shadow-pink-500/30 cursor-default",
					content: "drop-shadow shadow-black text-white",
				}}
			>
				<span className="font-medium">beta release</span>
			</Chip>
		</NavbarItem>
	);
};
