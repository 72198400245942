import { Button, NavbarItem } from "@nextui-org/react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useAuth } from "../Auth";
import { EventName, getMixPanelClient } from "@/app/mixpanel";

export const NavbarMyGenerations: React.FC = () => {
	const pathname = usePathname();
	const { isAuthenticated, isLoading } = useAuth();
	const mp = getMixPanelClient();
	if (!isAuthenticated || isLoading) return null;
	return (
		<NavbarItem isActive={pathname === "/characters"}>
			<Button
				variant="bordered"
				className="border-white/80 font-medium"
				radius="full"
				as={Link}
				href={"/characters"}
				onPress={() => {
					const baseUrl = window.location.origin;
					mp.track(EventName.PageView, {
						page: "User Generations",
						current_url_path: "/characters",
						$current_url: baseUrl + "/characters",
					});
				}}
			>
				My characters
			</Button>
		</NavbarItem>
	);
};
