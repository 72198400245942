import React, { useEffect, useState, useCallback } from "react";
import {
	Button,
	Divider,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	Textarea,
} from "@nextui-org/react";
import { ReportBugCategory } from "./ReportBugCategory";
import type { Selection } from "@nextui-org/react";
import { useAuth } from "../Auth";
import { IoClose } from "react-icons/io5";

interface ReportBugPopoverProps {
	isOpen: boolean;
	onClose: () => void;
}

interface ReportFormData {
	name: string;
	email: string;
	category: string;
	description: string;
	reportTime: string;
	userId?: string | undefined;
}

export const ReportBugModal: React.FC<ReportBugPopoverProps> = ({
	isOpen,
	onClose,
}) => {
	const { user } = useAuth();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [category, setCategory] = useState<Selection>(
		new Set(["Pick a category"])
	);
	const [description, setDescription] = useState("");
	const [errors, setErrors] = useState<{
		name?: string;
		email?: string;
		category?: string;
		description?: string;
	}>({});
	const [submitText, setSubmitText] = useState("Submit");

	const [isSubmitting, setIsSubmitting] = useState(false);

	const validateForm = () => {
		const newErrors: typeof errors = {};

		if (name.trim() === "") {
			newErrors.name = "Name is required";
		}

		if (email.trim() === "") {
			newErrors.email = "Email is required";
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = "Email is invalid";
		}

		if (Array.from(category)[0] === "Pick a category") {
			newErrors.category = "Category is required";
		}

		if (description.trim() === "") {
			newErrors.description = "Description is required";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const resetForm = useCallback(
		(isOpen: boolean) => {
			if (!isOpen) {
				if (user) {
					setName(user.nickname || "");
					setEmail(user.email || "");
				} else {
					setName("");
					setEmail("");
				}
				setCategory(new Set(["Pick a category"]));
				setDescription("");
				setErrors({});
			}
		},
		[user]
	);

	const handleSubmit = async () => {
		if (validateForm()) {
			const selectedCategory = Array.from(category)[0] as string;
			const reportFormData: ReportFormData = {
				name,
				email,
				category: selectedCategory,
				description,
				reportTime: new Date().toISOString(),
			};
			if (user && user.sub) {
				reportFormData.userId = user.sub;
			}
			console.log("Form submitted:", reportFormData);
			const formDataEntries = Object.entries(reportFormData);
			const formData = new FormData();
			formDataEntries.forEach(([key, value]) => {
				formData.append(key, value as string);
			});
			setIsSubmitting(true);
			setSubmitText("Submitting...");
			const response = await fetch("/api/v1/report-bug", {
				method: "POST",
				body: formData,
			});
			setIsSubmitting(false);
			if (!response.ok) {
				setErrors({ description: "Failed to submit report" });
				setSubmitText("Failed to submit");
				setTimeout(() => {
					setSubmitText("Submit");
				}, 2000);
			} else {
				setSubmitText("Submitted!");
				setTimeout(() => {
					setSubmitText("Submit");
				}, 2000);
				resetForm(false);
			}
		}
	};

	useEffect(() => {
		if (user) {
			setName(user.nickname || "");
			setEmail(user.email || "");
		}
	}, [user]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			scrollBehavior="inside"
			className="max-w-md mx-auto"
			placement="bottom-center"
			closeButton={
				<Button isIconOnly variant="light">
					<IoClose size={24} />
				</Button>
			}
		>
			<ModalContent>
				<ModalHeader className="text-lg  sm:text-2xl text-center font-semibold">
					<span className="mx-auto">Report a bug</span>
				</ModalHeader>
				<ModalBody>
					<p className="text-xs sm:text-sm text-white/80 text-center">
						Your feedback is extremely valuable to us. If you faced a bug or
						have any issues or suggestions, please let us know.
					</p>
					<Divider />
					<Input
						label="Name"
						size="sm"
						variant="bordered"
						radius="sm"
						isRequired
						value={name}
						onChange={(e) => setName(e.target.value)}
						errorMessage={errors.name}
						isInvalid={!!errors.name}
					/>
					<Input
						label="Email"
						size="sm"
						variant="bordered"
						radius="sm"
						isRequired
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						errorMessage={errors.email}
						isInvalid={!!errors.email}
					/>
					<ReportBugCategory
						selectedCategory={category}
						onCategoryChange={setCategory}
					/>
					{errors.category && (
						<span className="text-danger text-xs">{errors.category}</span>
					)}
					<Textarea
						label="Description"
						placeholder="Enter your description"
						variant="bordered"
						radius="sm"
						isRequired
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						errorMessage={errors.description}
						isInvalid={!!errors.description}
						minRows={3}
						maxRows={5}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="light"
						startContent={<IoClose size={18} />}
						color="danger"
						onPress={onClose}
					>
						Close
					</Button>
					<Button
						variant="ghost"
						className="w-1/2 mx-auto"
						radius="sm"
						onPress={handleSubmit}
						isLoading={isSubmitting}
						color={submitText === "Submitted!" ? "success" : "primary"}
					>
						<span className="font-medium text-sm">{submitText}</span>
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
