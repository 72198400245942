import Link from "next/link";
import { MdAdminPanelSettings } from "react-icons/md";

export const AdminPanel: React.FC = () => {
	return (
		<>
			<Link href="/admin/users" className="text-sm flex">
				Admin dashboard
				<MdAdminPanelSettings size={18} className="ml-1" />
			</Link>
		</>
	);
};
